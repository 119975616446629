import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [
  {path: 'pages', loadChildren: './pages/pages.module#PagesModule'},
  {path: '', redirectTo: 'pages/simulator', pathMatch: 'full'},
  {path: '**', redirectTo: 'pages/simulator'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
