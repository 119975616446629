import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';

@Injectable()

export class NotificationsService {
  public downloadAlert(title: string = 'Un momento por favor', text: string = 'Cargando información') {
    Swal.fire({
      title,
      text,
      onOpen: () => {
        Swal.showLoading();
      },
      allowOutsideClick: false
    });
  }

  public errorAlert(
    title: string = 'Se ha presentado un error',
    text: string = 'Por favor vuelva intentarlo más tarde.',
    showConfirmButton: boolean = true
  ) {
    Swal.fire({
        title,
        text,
        type: 'error',
        showConfirmButton,
        allowOutsideClick: false
      }
    );
  }

  public successAlert(title: string = 'Perfecto', text: string = 'Pronto un asesor se pondrá en contacto contigo') {
    Swal.fire(
      {
        title,
        text,
        type: 'success',
        allowOutsideClick: false
      }
    );
  }

  public warningAlert(title: string = 'Atención!', text: string = 'Debe revisar el proceso a afectuar') {
    Swal.fire(
      {
        title,
        text,
        type: 'warning',
        allowOutsideClick: false
      }
    );
  }

  public buildAlert(
    title: string = '¡Oops! ¡Lo sentimos!',
    text: string = 'Lo que buscas ya no se encuentra disponible.',
    showConfirmButton: boolean = true
  ) {
    Swal.fire({
        title,
        text,
        imageUrl: 'assets/images/const_icon.png',
        imageWidth: 100,
        imageHeight: 100,
        showConfirmButton,
        allowOutsideClick: false
      }
    );
  }

  public closeAlert() {
    Swal.close();
  }
}
