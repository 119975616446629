import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has('skipJwtHeader')) {
      const headers = request.headers.delete('skipJwtHeader');
      return next.handle(request.clone({headers}));
    }
    // add authorization header with jwt token if available
    const token = localStorage.getItem('t');
    if (token) {
      request = request.clone({
        setHeaders: {
          tokenKeyValue: token
        }
      });
    }
    // add authorization zoho header with jwt token if available
    const tokenZoho = localStorage.getItem('tz');
    if (tokenZoho) {
      request = request.clone({
        setHeaders: {
          tokenZohoKeyValue: tokenZoho
        }
      });
    }
    return next.handle(request);
  }
}
